import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import dayjs from 'dayjs'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ToggleButton from '@mui/material/ToggleButton'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import Radio from '@mui/material/Radio'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import useRoute from 'hooks/useNavigate'
import AddressInput from 'components/form/AddressInput'
import { type Member } from 'types'
import {
  type CategoriesRawData,
  type CategoryData,
} from 'components/category/categoryTypes'
import {
  type NewCaseInfo,
  type CaseCreationFormData,
} from 'components/case/caseTypes'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import FileUploader from 'components/form/FileUploader'
import useApi from 'hooks/useApi'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  EMAIL_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  Path,
} from '../commonConstants'
import {
  CASE_DESCRIPTION_MAX_LENGTH,
  CASE_ATTACHMENTS_MAX_COUNT,
  CASE_TITLE_MAX_LENGTH,
  AddCaseStep,
  Shareability,
} from 'components/case/caseConstants'
import CategorySelector from 'components/category/CategorySelector'
import {
  Accordion,
  BackButtonWhite,
  InfoText,
  MainHeader,
  SubHeader,
  Switch,
  SwitchWrapper,
  RadioGroup,
  RadioWrapper,
  SubSubHeader,
  FormFieldsWrapper,
} from 'components/StyledComponents'
import {
  hasUnsavedChangesState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import { selectedLocationInfoState } from 'state/mapStates'
import { portalSettingState } from 'state/portalSettingStates'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'
import DateInput from 'components/form/DateInput'
import AnonymousIcon from 'assets/icons/anonymous.svg'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import { ItemPrivacyMode } from 'components/item/itemConstants'
import { convertCategoriesRawData } from 'utils/categoryUtils'
import { type Item } from 'components/item/itemTypes'
import CaseShareabilityIcon from 'components/case/CaseShareabilityIcon'
import { userAccessState } from 'state/userStates'

const ItemWrapper = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
`

const FORM_NAME = 'case'

const ItemButton = styled(ToggleButton)(({ theme }) => ({
  width: '230px',
  '&:hover': {
    background: theme.palette.primary.contrastText,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  justifyContent: 'start',
}))

const CaseAddPage: React.FC = () => {
  const { goTo } = useRoute()
  const { formatMessage, locale } = useIntl()
  const userAccess = useRecoilValue(userAccessState)
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedContent, formatPhoneNumber, retrieveCategoryDataNames } =
    usePortalSetting()
  const { data: portalItemsData, isLoading: isLoadingItems } = useSWR<Item[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/items`
      : null,
  )
  const { data: portalMembersData } = useSWR<Member[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/members`
      : null,
  )

  const { data: categoriesRawData, isLoading: isLoadingCategories } =
    useSWR<CategoriesRawData>(
      portalSetting
        ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/categories`
        : null,
    )

  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | null
  >()
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)
  const [currentStep, setCurrentStep] = useState<AddCaseStep | null>(
    AddCaseStep.ITEM,
  )
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const { sendPostRequest } = useApi()
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const selectedLocationInfo = useRecoilValue(selectedLocationInfoState)
  const [isSaving, setIsSaving] = useState(false)
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
  } = useForm<CaseCreationFormData>({
    mode: 'onTouched',
  })

  const categoryIds = watch('categoryIds')
  const shareability = watch('shareability')

  useEffect(() => {
    setValue('occurred', dayjs(new Date()))
    setValue('shareability', Shareability.SHAREABLE)
  }, [])

  const sortedItems = useMemo(
    () =>
      portalItemsData?.sort((a, b) => {
        const nameA = getLocalizedContent(a.names).toUpperCase()
        const nameB = getLocalizedContent(b.names).toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    [portalItemsData, locale],
  )

  useEffect(() => {
    if (userAccess && portalMembersData) {
      const result = portalMembersData.find(
        (portalMember) => portalMember.email === userAccess.userData.email,
      )

      if (result) {
        setValue('reporterUserId', userAccess.userData.id)
      }
    }
  }, [userAccess, portalMembersData])

  useEffect(() => {
    if (portalItemsData?.length === 1) {
      setSelectedItemId(portalItemsData[0].id)
      setCurrentStep(AddCaseStep.ADDRESS)
    }
  }, [portalItemsData])

  const anonymous = watch('anonymous')

  useEffect(() => {
    if (anonymous) {
      setValue('reporterName', '')
      setValue('reporterEmail', '')
      setValue('reporterPhone', '')
    }
  }, [anonymous])

  const hasUnsavedChanges = useMemo(
    () =>
      processedFormFiles.length > 0 ||
      !!selectedLocationInfo ||
      !!selectedItemId ||
      isDirty ||
      !!categoryIds,
    [
      processedFormFiles,
      selectedLocationInfo,
      selectedItemId,
      isDirty,
      categoryIds,
    ],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const selectedPortalItem = useMemo((): Item | null => {
    if (portalItemsData && selectedItemId) {
      const selectedItem = portalItemsData.find(
        (item) => item.id === selectedItemId,
      )

      return selectedItem ?? null
    }

    return null
  }, [portalItemsData, selectedItemId])

  const handleSelectCaseItem = useCallback(
    (itemId: string): void => {
      setSelectedItemId(itemId)

      setCurrentStep(AddCaseStep.ADDRESS)
    },
    [portalItemsData],
  )

  const handleStepChange =
    (newStep: AddCaseStep) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setCurrentStep(isExpanded ? newStep : null)
    }

  const onSubmit: SubmitHandler<CaseCreationFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const reporter = {
            userId: data.reporterUserId ?? null,
            fullName: data.reporterUserId ? null : data.reporterName ?? '',
            email: data.reporterUserId ? null : data.reporterEmail ?? '',
            phone: data.reporterUserId
              ? null
              : formatPhoneNumber(data.reporterPhone ?? ''),
          }
          const formData: NewCaseInfo = {
            category: data.categoryIds,
            location: selectedLocationInfo,
            itemId: selectedItemId,
            description: data.description,
            title: data.title,
            locale,
            occurred: data.occurred?.format() || new Date().toISOString(),
            shareability: data.shareability,
          }

          if (!data.anonymous) {
            formData.reporter = reporter
          }

          if (processedFormFiles.length) {
            formData.resources = processedFormFiles.map((processedFile) => ({
              uri: processedFile.url,
              format: processedFile.format,
              name: processedFile.file.name,
            }))
          }

          const response = await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/cases`,
            formData,
          )

          const { id } = await response.json()

          goTo(`${Path.CASES_LIST}/${id as string}`, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [selectedLocationInfo, locale, processedFormFiles, selectedItemId],
  )

  const handleClickNextStep = useCallback((newStep: AddCaseStep): void => {
    setCurrentStep(newStep)
  }, [])

  const handleGoBack = (): void => {
    goTo(Path.CASES_LIST)
  }

  const categoriesData = useMemo((): CategoryData[] => {
    if (!categoriesRawData) {
      return []
    }

    return convertCategoriesRawData(categoriesRawData)
  }, [categoriesRawData])

  const handleCategoryChange = useCallback(
    (categoryIds: string): void => {
      if (categoriesRawData) {
        setValue('categoryIds', categoryIds)
        setSelectedCategoryName(
          retrieveCategoryDataNames(categoryIds, categoriesData).join(' > '),
        )
        setCurrentStep(AddCaseStep.CATEGORY)
      }
    },
    [categoriesRawData, locale],
  )

  const descriptionErrorMessage = useMemo((): string | undefined => {
    if (errors.description?.type === 'maxLength') {
      return formatMessage(
        {
          id: 'case_add.step.case_information.error.max_length_description',
        },
        {
          max: CASE_DESCRIPTION_MAX_LENGTH,
        },
      )
    }

    if (errors.description?.type === 'required') {
      return formatMessage({
        id: 'case_add.step.case_information.error.required_description',
      })
    }

    return undefined
  }, [errors.description])

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      Object.keys(errors).length > 0 ||
      isFileUploading ||
      !selectedLocationInfo ||
      (!categoryIds && !!categoriesRawData?.categoryInfo.categories?.length),
    [
      isValid,
      errors,
      isSaving,
      isFileUploading,
      categoryIds,
      categoriesRawData,
    ],
  )

  const filteredMembers = useMemo(() => {
    return (portalMembersData ?? []).filter((member) => member.active)
  }, [portalMembersData])

  if (isLoadingItems || isLoadingCategories) {
    return <CircularProgress />
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={0} sm={0} md={1} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={10} lg={6}>
          <Stack spacing={2} width={'100%'} padding={2}>
            <Stack direction={'row'} spacing={2}>
              <BackButtonWhite
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonWhite>
              <MainHeader>
                {formatMessage({ id: 'case_add.header' })}
              </MainHeader>
            </Stack>

            {sortedItems && sortedItems.length > 1 && (
              <Accordion
                defaultExpanded={true}
                expanded={currentStep === AddCaseStep.ITEM}
                onChange={handleStepChange(AddCaseStep.ITEM)}
                disableGutters
                elevation={0}
                data-testid="related-item-accordion"
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <CheckCircleIcon
                      color={selectedItemId ? 'success' : 'disabled'}
                    />

                    <SubHeader>
                      {selectedPortalItem && currentStep !== AddCaseStep.ITEM
                        ? getLocalizedContent(selectedPortalItem.names)
                        : formatMessage({
                            id: 'case_add.step.choose_related_item',
                          })}
                    </SubHeader>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingX: 6 }}>
                  <ItemWrapper direction={'row'}>
                    {sortedItems.map((item) => (
                      <ItemButton
                        key={item.id}
                        size="small"
                        value={item.id}
                        selected={selectedItemId === item.id}
                        onChange={() => {
                          handleSelectCaseItem(item.id)
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                        >
                          {item.logoUrl && (
                            <LogoWrapper
                              size={40}
                              url={item.logoUrl}
                              alt={getLocalizedContent(item.names)}
                            />
                          )}
                          <Box>{getLocalizedContent(item.names)}</Box>
                        </Stack>
                      </ItemButton>
                    ))}
                  </ItemWrapper>
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion
              defaultExpanded={false}
              expanded={currentStep === AddCaseStep.ADDRESS}
              onChange={handleStepChange(AddCaseStep.ADDRESS)}
              disabled={!selectedItemId}
              disableGutters
              elevation={0}
              data-testid="case-address-accordion"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={
                      selectedLocationInfo?.address ? 'success' : 'disabled'
                    }
                  />

                  <SubHeader>
                    {selectedLocationInfo && currentStep !== AddCaseStep.ADDRESS
                      ? selectedLocationInfo.address
                      : formatMessage({
                          id: 'case_add.step.specify_case_location',
                        })}
                  </SubHeader>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingX: 6 }}>
                <Stack spacing={2}>
                  {portalSetting && (
                    <AddressInput
                      mapHeight={400}
                      selectedPosition={
                        selectedPortalItem?.locationDetails?.location
                          ?.position || portalSetting.mapConfiguration?.centre
                      }
                      center={portalSetting.mapConfiguration?.centre}
                      region={portalSetting.mapConfiguration?.region}
                      language={portalSetting.defaultLanguage.toLowerCase()}
                      zoom={portalSetting.mapConfiguration?.zoomLevel}
                    />
                  )}

                  <Box textAlign={'right'}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClickNextStep(AddCaseStep.REPORTER)
                      }}
                    >
                      {formatMessage({ id: 'general.button.next' })}
                    </Button>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded={false}
              expanded={currentStep === AddCaseStep.REPORTER}
              onChange={handleStepChange(AddCaseStep.REPORTER)}
              disabled={!selectedItemId}
              disableGutters
              elevation={0}
              data-testid="reporter-info-accordion"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={
                      anonymous ||
                      getValues('reporterName') ||
                      getValues('reporterEmail') ||
                      getValues('reporterPhone')
                        ? 'success'
                        : 'disabled'
                    }
                  />
                  <SubHeader>
                    {getValues('reporterName') &&
                    currentStep !== AddCaseStep.REPORTER
                      ? getValues('reporterName')
                      : formatMessage({
                          id: 'case_add.step.reporter_information',
                        })}
                  </SubHeader>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingX: 6 }}>
                <Stack spacing={2}>
                  {selectedPortalItem?.anonymousReporting && (
                    <SwitchWrapper direction="row" spacing={2}>
                      <AnonymousIcon color="primary" />
                      <Box flexGrow={1}>
                        <Typography variant="body1">
                          {formatMessage({
                            id: 'case_add.label.send_anonymously',
                          })}
                        </Typography>
                        <InfoText>
                          {formatMessage({
                            id: 'case_add.text.send_anonymously',
                          })}
                        </InfoText>
                      </Box>
                      <Box>
                        <Controller
                          name="anonymous"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              defaultChecked={field.value}
                              color="primary"
                              onChange={(e) => {
                                field.onChange(e)
                                setValue('anonymous', e.target.checked)
                              }}
                            />
                          )}
                        />
                      </Box>
                    </SwitchWrapper>
                  )}

                  <FormControl error={!!errors.reporterName}>
                    <Controller
                      name="reporterName"
                      control={control}
                      rules={{
                        maxLength: USER_NAME_MAX_LENGTH,
                        required: true && !anonymous,
                      }}
                      defaultValue={`${userAccess?.userData.firstName} ${userAccess?.userData.lastName}`}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          disabled={anonymous}
                          options={filteredMembers}
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option
                            }

                            return (
                              `${option.firstName} ${option.lastName}` ?? ''
                            )
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              {option.firstName} {option.lastName} (
                              {option.email})
                            </li>
                          )}
                          onChange={(event, newValue): void => {
                            console.log(newValue)
                            if (typeof newValue === 'string') {
                              setValue('reporterName', newValue)
                              setValue('reporterEmail', '')
                              setValue('reporterPhone', '')
                              setValue('reporterUserId', null)
                            } else if (newValue) {
                              setValue(
                                'reporterName',
                                `${newValue.firstName} ${newValue.lastName}`,
                              )
                              setValue('reporterEmail', newValue.email)
                              setValue('reporterUserId', newValue.user.id)
                              setValue('reporterPhone', newValue.phone)
                            }
                          }}
                          onInputChange={(event, newInputValue) => {
                            setValue('reporterName', newInputValue)

                            if (
                              newInputValue !==
                              `${userAccess?.userData?.firstName} ${userAccess?.userData?.lastName}`
                            ) {
                              setValue('reporterUserId', null)
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!errors.reporterName}
                              required
                              size="small"
                              label={formatMessage({
                                id: 'case_add.step.reporter_information.label.name',
                              })}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                    {errors.reporterName?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'case_add.step.reporter_information.error.max_length_name',
                          },
                          {
                            max: USER_NAME_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Stack direction="row" spacing={2}>
                    <FormControl error={!!errors.reporterEmail} fullWidth>
                      <Controller
                        name="reporterEmail"
                        control={control}
                        rules={{
                          maxLength: EMAIL_MAX_LENGTH,
                          required: true && !anonymous,
                          validate: (val): boolean => {
                            if (!val) {
                              return true
                            }

                            return isValidEmail(val)
                          },
                        }}
                        defaultValue={userAccess?.userData?.email ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={anonymous}
                            error={!!errors.reporterEmail}
                            size="small"
                            label={formatMessage({
                              id: 'case_add.step.reporter_information.label.email',
                            })}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      {errors.reporterEmail?.type === 'validate' && (
                        <FormHelperText>
                          {formatMessage({
                            id: 'general.error.validate_email',
                          })}
                        </FormHelperText>
                      )}
                      {errors.reporterEmail?.type === 'maxLength' && (
                        <FormHelperText>
                          {formatMessage(
                            {
                              id: 'general.error.max_length_email',
                            },
                            { max: EMAIL_MAX_LENGTH },
                          )}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl error={!!errors.reporterPhone} fullWidth>
                      <Controller
                        name="reporterPhone"
                        control={control}
                        rules={{
                          maxLength: PHONE_MAX_LENGTH,
                          validate: (val): boolean => {
                            if (!val) {
                              return true
                            }

                            return isValidPhoneNumber(val)
                          },
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={anonymous}
                            error={!!errors.reporterPhone}
                            size="small"
                            label={formatMessage({
                              id: 'case_add.step.reporter_information.label.phone',
                            })}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      {errors.reporterPhone?.type === 'maxLength' && (
                        <FormHelperText>
                          {formatMessage(
                            {
                              id: 'general.error.max_length_phone',
                            },
                            {
                              max: PHONE_MAX_LENGTH,
                            },
                          )}
                        </FormHelperText>
                      )}
                      {errors.reporterPhone?.type === 'validate' && (
                        <FormHelperText>
                          {formatMessage({
                            id: 'general.error.validate_phone',
                          })}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Box textAlign={'right'}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClickNextStep(AddCaseStep.CASE_INFO)
                      }}
                    >
                      {formatMessage({ id: 'general.button.next' })}
                    </Button>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded={false}
              expanded={currentStep === AddCaseStep.CASE_INFO}
              onChange={handleStepChange(AddCaseStep.CASE_INFO)}
              disabled={!selectedItemId}
              disableGutters
              elevation={0}
              data-testid="case-info-accordion"
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={getValues('description') ? 'success' : 'disabled'}
                  />

                  <SubHeader>
                    {formatMessage({
                      id: 'case_add.step.case_information',
                    })}
                  </SubHeader>
                </Stack>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingX: 6 }}>
                <Stack spacing={2}>
                  {(selectedPortalItem?.privacyMode ===
                    ItemPrivacyMode.COMMUNITY_MODE ||
                    selectedPortalItem?.privacyMode ===
                      ItemPrivacyMode.OPEN_MODE) && (
                    <FormFieldsWrapper>
                      <SubSubHeader>
                        {formatMessage({
                          id: 'case_add.label.case_visibility',
                        })}
                      </SubSubHeader>
                      <Controller
                        name="shareability"
                        control={control}
                        defaultValue={Shareability.PRIVATE}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            onChange={(e) => {
                              field.onChange(e)
                              setValue(
                                'shareability',
                                e.target.value as Shareability,
                              )
                            }}
                          >
                            <RadioWrapper
                              key={Shareability.PRIVATE}
                              value={Shareability.PRIVATE}
                              selected={
                                shareability === Shareability.PRIVATE
                                  ? 'selected'
                                  : ''
                              }
                              control={<Radio />}
                              labelPlacement="start"
                              label={
                                <Stack
                                  spacing={1}
                                  flexGrow={1}
                                  direction={'row'}
                                  alignItems="center"
                                >
                                  <CaseShareabilityIcon
                                    shareability={Shareability.PRIVATE}
                                  />
                                  <Stack flexGrow={1}>
                                    <Typography>
                                      {formatMessage({
                                        id: 'case_detail.label.private',
                                      })}
                                    </Typography>
                                    <Typography variant="caption">
                                      {formatMessage({
                                        id: 'case_add.text.private',
                                      })}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              }
                            />
                            <RadioWrapper
                              key={Shareability.SHAREABLE}
                              value={Shareability.SHAREABLE}
                              selected={
                                shareability === Shareability.SHAREABLE
                                  ? 'selected'
                                  : ''
                              }
                              control={<Radio />}
                              labelPlacement="start"
                              label={
                                <Stack
                                  spacing={1}
                                  flexGrow={1}
                                  direction={'row'}
                                  alignItems="center"
                                >
                                  <CaseShareabilityIcon
                                    shareability={Shareability.SHAREABLE}
                                  />
                                  <Stack flexGrow={1}>
                                    <Typography>
                                      {formatMessage({
                                        id: 'case_detail.label.shareable',
                                      })}
                                    </Typography>
                                    <Typography variant="caption">
                                      {formatMessage({
                                        id: 'case_add.text.shareable',
                                      })}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              }
                            />
                            <RadioWrapper
                              key={Shareability.SHARED}
                              value={Shareability.SHARED}
                              selected={
                                shareability === Shareability.SHARED
                                  ? 'selected'
                                  : ''
                              }
                              control={<Radio />}
                              labelPlacement="start"
                              label={
                                <Stack
                                  spacing={1}
                                  flexGrow={1}
                                  direction={'row'}
                                  alignItems="center"
                                >
                                  <CaseShareabilityIcon
                                    shareability={Shareability.SHARED}
                                  />
                                  <Stack flexGrow={1}>
                                    <Typography>
                                      {formatMessage({
                                        id: 'case_detail.label.shared',
                                      })}
                                    </Typography>
                                    <Typography variant="caption">
                                      {formatMessage({
                                        id: 'case_add.text.shared',
                                      })}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              }
                            />
                          </RadioGroup>
                        )}
                      />
                    </FormFieldsWrapper>
                  )}

                  <FormControl error={!!errors.title}>
                    <Controller
                      name="title"
                      control={control}
                      rules={{
                        maxLength: CASE_TITLE_MAX_LENGTH,
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.title}
                          size="small"
                          label={formatMessage({
                            id: 'case_add.step.case_information.label.title',
                          })}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.title?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          {
                            max: CASE_TITLE_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.description}>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: CASE_DESCRIPTION_MAX_LENGTH,
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <MultiLineInput
                          {...field}
                          required
                          error={!!errors.description}
                          maxLength={CASE_DESCRIPTION_MAX_LENGTH}
                          label={formatMessage({
                            id: 'case_add.step.case_information.label.description',
                          })}
                          variant="outlined"
                          fullWidth
                          rows={5}
                          helpMessage={descriptionErrorMessage}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <Controller
                      name="occurred"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          {...field}
                          label={formatMessage({
                            id: 'case_add.label.occurred',
                          })}
                          allowOnlyPast={true}
                          showTime={true}
                        />
                      )}
                    />
                  </FormControl>

                  <FileUploader
                    formName={FORM_NAME}
                    accept={[
                      FILE_INPUT_ACCEPT_TYPE.IMAGE,
                      FILE_INPUT_ACCEPT_TYPE.DOCUMENT,
                      FILE_INPUT_ACCEPT_TYPE.EMAIL,
                    ]}
                    limit={CASE_ATTACHMENTS_MAX_COUNT}
                  />
                </Stack>

                <Box textAlign={'right'} marginTop={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleClickNextStep(AddCaseStep.CATEGORY)
                    }}
                  >
                    {formatMessage({ id: 'general.button.next' })}
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>

            {!!categoriesRawData?.categoryInfo.categories?.length && (
              <Accordion
                defaultExpanded={false}
                expanded={currentStep === AddCaseStep.CATEGORY}
                onChange={handleStepChange(AddCaseStep.CATEGORY)}
                disabled={!selectedItemId}
                disableGutters
                elevation={0}
                data-testid="category-accordion"
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <CheckCircleIcon
                      color={selectedCategoryName ? 'success' : 'disabled'}
                    />

                    <SubHeader>
                      {selectedCategoryName &&
                      currentStep !== AddCaseStep.CATEGORY
                        ? selectedCategoryName
                        : formatMessage({
                            id: 'case_add.step.select_category',
                          })}
                    </SubHeader>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingX: 6 }}>
                  <CategorySelector
                    selectedCategoryIds={categoryIds}
                    categories={categoriesData}
                    isLoading={isLoadingCategories}
                    onCategoryChange={handleCategoryChange}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            <Box marginTop={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                fullWidth
                loading={isSaving}
                disabled={shouldDisableSaving}
                color="secondary"
              >
                {formatMessage({ id: 'case_add.button.create_case' })}
              </LoadingButton>

              <Button variant="text" onClick={handleGoBack} fullWidth>
                {formatMessage({ id: 'general.button.cancel' })}
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={0} sm={0} md={1} lg={3}></Grid>
      </Grid>
    </form>
  )
}

export default CaseAddPage
