import { type MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/material/styles/styled'
import { grey } from '@mui/material/colors'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorIcon from '@mui/icons-material/Error'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import { LoadingWrapper } from 'components/StyledComponents'
import { PromiseStatus } from '../../commonConstants'
import { type ProcessedFile } from 'utils/fileTypes'
import ResourceWrapper from 'components/resource/ResourceWrapper'

interface UploadedFilesPreviewProps {
  processedFormFiles: ProcessedFile[]
  onDeleteFile: (targetId: string | number) => void
  limit: number
  addFileIcon?: typeof UploadFileIcon
}

const Wrapper = styled(Stack)`
  flex-wrap: wrap;
`

const PreviewResourceWrapper = styled(Box)`
  position: relative;
  width: 90px;
  height: 90px;

  & > .MuiButtonBase-root {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    z-index: 2;
  }

  & > .MuiSvgIcon-root {
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 18px);
  }

  &:hover {
    .MuiButtonBase-root {
      display: block;
    }
  }

  & > img {
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  }
`

const AddResourceWrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`

const UploadedFilesPreview: React.FC<UploadedFilesPreviewProps> = ({
  processedFormFiles,
  onDeleteFile,
  limit,
  addFileIcon,
}) => {
  const { formatMessage } = useIntl()
  const AddFileIcon = addFileIcon || UploadFileIcon

  const handlePreviewImageClick = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
  }

  console.log(processedFormFiles)

  return (
    <Wrapper direction="row" spacing={2}>
      {processedFormFiles?.map((processedFile) => (
        <PreviewResourceWrapper
          key={processedFile.id}
          onClick={handlePreviewImageClick}
        >
          <ResourceWrapper
            format={processedFile.format}
            size={89}
            base64={processedFile.base64}
            url={processedFile.url}
            imageSize="contain"
          />

          {!processedFile.status && (
            <LoadingWrapper>
              <CircularProgress size={20} />
            </LoadingWrapper>
          )}

          {processedFile.status === PromiseStatus.REJECTED && (
            <ErrorIcon color="error" fontSize="large" />
          )}

          <IconButton
            aria-label={formatMessage({
              id: 'general.icon_button.delete',
            })}
            onClick={() => {
              onDeleteFile(processedFile.id)
            }}
          >
            <CancelIcon color="error" fontSize="inherit" />
          </IconButton>
        </PreviewResourceWrapper>
      ))}

      {processedFormFiles.length < limit && processedFormFiles.length > 0 && (
        <AddResourceWrapper height={80} width={80}>
          <AddFileIcon fontSize="large" sx={{ color: grey[500] }} />
        </AddResourceWrapper>
      )}
    </Wrapper>
  )
}

export default UploadedFilesPreview
